/* Shared form check custom styles */
.form-check-custom {
    position: relative;
    padding: 0;

    input {
        position: absolute;
        opacity: 0;
        margin: 0;

        &:checked+label {
            &:before {
                border-color: var(--theme-form-checkbox-active-color);
            }

            &:after {
                opacity: 1;
            }
        }

        &:disabled+label {
            cursor: default;
        }
    }

    label {
        margin: 0;
        cursor: pointer;

        &:before {
            width: var(--theme-form-checkbox-before-size);
            height: var(--theme-form-checkbox-before-size);
            border-radius: var(--theme-form-checkbox-border-radius);
            display: inline-block;
            content: "";
            border: 1px solid $border-color;
            vertical-align: text-bottom;
            margin-right: .5rem;
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            height: var(--theme-form-checkbox-after-height);
            width: var(--theme-form-checkbox-after-width);
            border-bottom: var(--theme-form-checkbox-after-border-width) solid var(--theme-form-checkbox-active-color);
            border-right: var(--theme-form-checkbox-after-border-width) solid var(--theme-form-checkbox-active-color);
            transform: rotate(45deg);
            left: var(--theme-form-checkbox-after-position-left);
            top: var(--theme-form-checkbox-after-position-top);
            opacity: 0;
        }
    }
}


/* Custom checkbox styling. Checkbox has a border around the label. 
No "checkbox" is displayed. When user clicks, label bg is changed. */
.form-check-bg {
    input {
        position: absolute;
        opacity: 0;
        margin: 0;

        &:checked+label {
            background: var(--theme-form-checkbox-active-color);
            border-color: var(--theme-form-checkbox-active-color);
            color: white;
        }
    }

    label {
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: .25rem .5rem;
        cursor: pointer;

        &:after,
        &:before {
            display: none !important;
        }

    }
}

/* Custom checkbox styling.
Checkbox always has solid background. Checked checkbox also has white checkmark. */
.form-check-solid-bg-checkmark {
    label {
        &:before {
            background: var(--theme-form-checkbox-active-color);
            border: 0;
            width: var(--theme-form-checkbox-bg-before-size);
            height: var(--theme-form-checkbox-bg-before-size);
        }

        &:after {
            border-bottom: var(--theme-form-checkbox-after-border-width) solid $white;
            border-right: var(--theme-form-checkbox-after-border-width) solid $white;
            left: var(--theme-form-checkbox-bg-after-position-left);
            top: var(--theme-form-checkbox-bg-after-position-top);
        }
    }
}

/* handle checkbox checkmark color variants
 Only CSS custom properties are changed here (CSS variables) */
@each $color,
$value in $theme-colors {
    .form-check-custom.form-check-#{$color} {
        --theme-form-checkbox-active-color: #{$value};
    }
}

/* Custom radio button styling.
Most of the styling comes from .form-check-custom styling. */
.form-radio-custom {
    label {

        display: block;
        padding-left: 1.95rem;

        &:before {
            border-radius: var(--theme-form-radio-button-border-radius);
            border: 2px solid $border-color;
            position: absolute;
            left: 0;
            top: 4px;
        }

        &:after {
            height: var(--theme-form-radio-button-after-size);
            width: var(--theme-form-radio-button-after-size);
            border-radius: var(--theme-form-radio-button-border-radius);
            left: 5px;
            top: 9px;
            background: var(--theme-form-checkbox-active-color);
        }
    }

}


/* Custom Radio Button Styling
No radio button is displayed. Instead, checked radio buton has active border and text color */
.form-radio-highlight {
    input {
        &:checked+label {
            border-color: var(--theme-form-checkbox-active-color);
            color: var(--theme-form-checkbox-active-color);
        }        
    }

    label {
        display: block;
        border: 1px solid $border-color;
        padding: 1rem;
        border-radius: $border-radius;
        cursor: pointer;

        * {
            color: inherit !important;
        }
        
        &:before, &:after {
            display: none !important;
        }
    }
}

/* product page custom radio and checkbox styling */
.form-check-option {
    position: relative;
    padding: 0;

    input {
        position: absolute;
        opacity: 0;
        margin: 0;

        &:checked+label {
            background: $gray-900;
            border-color: $gray-900;
            color: $white;
        }

        &:disabled+label {
            cursor: default;
            opacity: .5;
            background: $gray-50;
            text-decoration: line-through;
        }
    }

    label {
        border: 1px solid $border-color;
        padding: .6rem .75rem;
        min-width: 4rem;
        cursor: pointer;
        margin-right: .5rem;
        margin-bottom: .5rem;
        text-align: center;
        line-height: 1;
        font-size: .975rem;
    }
}

.form-check-rounded {
    label {
        border-radius: $border-radius;
    }
}