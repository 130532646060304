.swiper-overflow-visible {
    overflow: visible;


    .swiper-scrollbar {
        bottom: -45px;
        left: 0;
    }
}

.swiper-container-horizontal>.swiper-scrollbar {
    height: 4px;
    width: 100%;
}

.swiper-container-horizontal>.swiper-scrollbar {
    left: 0;
}

.card .swiper-button-disabled {
    opacity: 0;
    display: none !important;
}

.swiper-slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

/* Swiper timeline is vertical and needs fixed height */
.swiper-timeline {
    //height: var(--swiper-timeline-fixed-height);
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    font-size: 12px;
    opacity: 1;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
    transition: all 0.2s;
    overflow: hidden;
}

.swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 10px;
}

.vertical-pagination {
    .swiper-pagination-bullet-active {
        width: 8px;
        height: 20px;
    }
}

.swiper-pagination-bullet-light {
    .swiper-pagination-bullet {
        background: rgba(255,255,255, .9);
    }
}

.gallery-thumbs-vertical {
    .swiper-slide {
        cursor: pointer;
        border-bottom: 2px solid transparent;
        opacity: .5;

        &.swiper-slide-thumb-active {
            border-color: $gray-900;
            opacity: 1;
        }
    }
}

.gallery-thumbs-horizontal {
    .swiper-slide {
        border: 2px solid $border-color;
        opacity: .5;
        cursor: pointer;

        &.swiper-slide-thumb-active {
            border-color: $gray-900;
            opacity: 1;
        }

    }
}

.swiper-btn {
    cursor: pointer;
    transition: all ease-in .2s;

    &:hover {
        opacity: .50;
    }
}

.swiper-btn-side {
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 30;
}

.swiper-btn-side-right {
    left: auto;
    right: 0;
}

.swiper-btn-show-hover {
    transform: translateX(-20%);
    opacity: 0;
}

.swiper-btn-show-hover.swiper-btn-side-right  {
    transform: translateX(10%);
}

.swiper-container:hover {
    .swiper-btn-show-hover {
        transform: translateX(0);
        opacity: 1;
    }  
}

.swiper-button-disabled {
    opacity: .10;
}

.swiper-disabled-hide.swiper-button-disabled {
    opacity: 0 !important;
}

/* custom swiper styling for lookbook */
.lookbook-thumbs-horizontal {
    .swiper-slide {
        opacity: .1;
        cursor: pointer;
        transition: all linear .2s;

        &:hover, &.swiper-slide-thumb-active {
            opacity: 1;
        }
    }
}

/* Custom swiper pagination - blocks */
.swiper-pagination-blocks {

    .swiper-pagination-custom {
        display: flex;
        justify-content: space-between;

        .swiper-pagination-bullet {
            display: flex;
            width: auto;
            flex-grow: 1;
            border-radius: 0;
            margin: 0;
            margin-right: 1rem;
            background: $gray-100;
            height: 4px;
            cursor: default;

            &:last-child {
                margin-right: 0;
            }
            &.swiper-pagination-bullet-active {
                background: $gray-900;
            }
        }
    }
}