.marquee {
    overflow-x: hidden;
}

.marquee-body {
    display:flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 100%;  
}

.marquee-section {
    display : flex;
    flex-shrink: 0;
    align-items: center;
    overflow: hidden;
}

.marquee-hover-pause {
    &:hover {
        * {
            animation-play-state: paused;
        }
    }
}