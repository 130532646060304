.dropend .dropdown-menu {
    left: 98%;
}

.dropdown-toggle {
    position: relative;

    &:after {
        border: 0;
        font-family: var(--theme-font-icon);
        font-style: var(--theme-font-icon-style);
        content: "\EA50";
        margin: 0 !important;
        font-size: 1.15rem;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }

    &.show {
        &:after {
            content: "\EA56";
        }
    }

}


.dropdown-toggle:not(.dropdown-toggle-no-icon):not(.dropdown-toggle-xs) {
    padding-right: 1.25rem !important;
}

.dropdown-toggle-no-icon {
    &:after {
        display: none !important;
    }
}

.dropdown-toggle-xs {
    padding-right: .925rem !important;

    &:after {
        font-size: .9rem;
        transform: translateY(-38%);
    }
}

.dropend {
    .dropdown-toggle {
        &:after {
            content: "\EA6E";
            font-size: .85rem;
            border: 0 !important;
        }
    }
}


.dropdown-item {
    transition: all linear .2s;
}

.dropdown-menu {
    box-shadow: 0 1.5rem 4rem rgb(22 28 45 / 12%);
    padding: 1rem 1.5rem;
    min-width: 12rem;
}

.dropdown-menu-sm {
    min-width: auto;
}

.dropdown-menu-xs {
    min-width: auto;
    padding: 1rem;  
}


/* dropdown menu stylings*/
@include media-breakpoint-down(lg) { 
    .dropdown-megamenu .container-fluid {
        padding: 0 !important;
    }
}
@include media-breakpoint-up(lg) { 
    .dropdown-megamenu {
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 0 !important;
        padding: 0 !important;
        background: $white;
        margin: 0 !important;
        border: 0 !important;
        z-index: 9999;
        border-top: 1px solid $border-color !important;
        box-shadow: 0 1.5rem 31px -17px rgb(22 28 45 / 8%);
    }
    .dropdown-menu-lg {
        min-width: 46rem;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 2.5rem 3rem;
    }
    .navbar-nav .dropdown-menu.show:not(.dropdown-menu-xs) {
        animation: fadeInUp .3s;
    }
    
    .navbar-nav .dropdown-menu-lg.show:not(.dropdown-menu-xs) {
        animation: fadeInUpMenu .3s;
    }
}

@include media-breakpoint-down(lg) { 
    .dropdown-menu {
        padding: 1rem;
    }
    .nav-item .dropdown-menu {
        box-shadow: none;
    }
    .dropdown-menu .container {
        padding: 0;
    }
}

.dropdown-megamenu.border-0 {
    border: 0 !important;
}

.dropdown-heading {
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 74%;
    margin-bottom: 1rem;
    color: $text-muted;
    font-weight: 600;
}

.dropdown-list-item {
    margin-bottom: 0;
}

.dropdown-item {
    transition: all linear .3s;
    text-decoration: none;
    display: flex;
    padding: .25rem 0;

    &:hover {
        color: $text-muted;
        text-decoration: none;
    }
}

.dropdown-menu-xs .dropdown-item {
    padding: .25rem .5rem;
}

.dropdown-menu-xs .dropdown-item {
    font-size: 74%;
    font-weight: 500;
}

.dropdown-link-all {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.25em;
    font-size: 74%;
    margin-top: 1rem;
}