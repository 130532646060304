/* text link cover full area. Used mostly with cards */
.link-cover {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 30;
      pointer-events: auto;
      content: "";
    }
  }