/* Checkout component styles */

.aside-checkout {
    position: relative;

    &:after {
        background: $gray-100;
        width: 300%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        bottom: 0;
        z-index: -1;
    }
}

@include media-breakpoint-down(lg) {
    .aside-checkout:after {
        display: none;
    }
}

.nav-link-checkout {
    text-decoration: none;
    color: $text-muted;
    opacity: .75;
    position: relative;
    padding: 0;

    &:after {
        font-family: var(--theme-font-icon);
        content: "\EA6E";
        position: absolute;
        right: -1.25rem;
        top: 0;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $body-color;
        bottom: -5px;
        left: 0;
        right: 0;
        opacity: 0;
        transition: all linear .3s;
    }

    &.nav-link-last {
        &:after {
            display: none;
        }
    }

    &.active,
    &:hover,
    &:active,
    &:focus {
        color: $body-color;
        opacity: 1;

        &:before {
            opacity: 1;
        }
    }

}

/* Qty number used in checkout summary */
.checkout-item-qty {
    position: absolute;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    top: -8px;
    right: -8px;
    background: $gray-900;
    color: $white;
    font-size: 74%;
}

.page-loaded .nav-link-checkout {
    transition: all linear .3s;
}