.animation-float {
	animation: float 3s ease-in-out infinite;
}

/* loop from 1 - 10 for different floating option */
@for $i from 1 through 10 {
	.animation-float-#{$i} {
		animation: float #{$i}s ease-in-out infinite;
	}
}

/* loop from 1 - 10 for different marquee timing option */
@for $i from 1 through 10 {
	.animation-marquee-#{$i}0 {
		animation: marquee #{$i}0s linear infinite;
	}
}


@keyframes float {
	0% {
		transform: translate3d(0, 0, 0);
	}

	50% {
		transform: translate3d(0, 7px, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}

.animation-move  {
	animation: move 80s ease;
	position: absolute;
	animation-iteration-count: infinite;
  }

.animation-move-slow  {
	animation: move 120s ease;
	position: absolute;
	animation-iteration-count: infinite;
}
  
@keyframes move {
	0% {
	  transform-origin: bottom left;
	  transform: scale(1.0);
	}
	50% {
	  transform: scale(1.2);
	}
	100% {
	  transform: scale(1);
	}
  }


.animation-rotate {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

.animation-pulsate {
	animation: pulsate 2s infinite linear;
}

@keyframes pulsate {
	0% {
		transform: scale(1);
		opacity: 0.8;
	}

	45% {
		transform: scale(1.75);
		opacity: 0;
	}
}

@keyframes fadeIn {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeInLeft {
	0% {
		display: none;
		opacity: 0;
		transform: translateX(-100%);
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInRight {
	0% {
		display: none;
		opacity: 0;
		transform: translateX(100%);
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeOutRight {
	0% {
		display: block;
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		display: none;
		opacity: 0;
		transform: translateX(100%);
	}
}


@keyframes fadeInUp {
	0% {
		display: none;
		opacity: 0;
		transform: translateY(10px);
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInMenu {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}


@keyframes marquee {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(-100%, 0, 0);
	}
}