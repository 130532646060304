/* generic site wide styles*/
body {
    overflow-x: hidden;
    background: -webkit-linear-gradient(90deg, #FFF 25%, #f9f9f9 25%);
    background: -o-linear-gradient(90deg, #FFF 25%, #f9f9f9 25%);
    background: -moz-linear-gradient(90deg, #FFF 25%, #f9f9f9 25%);
    background: linear-gradient(90deg, #FFF 25%, #f9f9f9 25%);
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-font {
    font-family: var(--theme-font-headings);
}

.disable-child-pointer * {
    pointer-events: none;
}

#canvas {
    position: fixed;
    display: block;
    z-index: -10;
}